.event-time-container {
    border-radius: 9px;
    background-color: #fff;
}

.event-time-item .selected {
    background-color: #E5E4E9;
}

.event-time-item {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.disbled-slots{
    cursor: unset;
}

.event-time-title-container {
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 12px 0px;
}

.event-item-title{
 font-family: 'Roboto', sans-serif;
 font-weight: 700;
 font-size: 14px;
}