.vote-icon{
    height: 20px;
    width:20px;
}
.appointment-icon{
    height: 19px;
    width:19px;
}

.user-card{
    box-shadow: 0px 1px 1px #ccc !important;
    padding: 8px;
}

.slot-label{
    font-family: robotoBold;
    font-size: 15px;
}
.vote-label{
    font-family: robotoNormal;
    font-size: 15px;
}
.user-label{
    font-family: robotoNormal;
    font-size: 15px;
}

.slot-card{
    box-shadow: 2px 2px 7px #ccc !important;
}

.btn-invite {
    background: #e5e5ea;
    color: #0a0afe;
    border-radius: 6px;
    font-size: 14px;
    border: 0px;
    padding: 6px;
    width: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    height:33px;
    line-height: 15px;
}