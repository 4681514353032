.btn-save {
  background: #e5e5ea;
  color: #0a0afe;
  border-radius: 6px;
  font-size: 15px;
  border: 0px;
  padding: 10px;
  width: 160px;
  margin-left: 5px;
}
