.btn-add-slot {
    color: #000FC7 !important;
}

.availability-name-label {
    color: darkgray;
    font-family: robotoNormal;
    font-size: 12px;
}

.txt-availability-name {
    border: 0px !important;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px !important;
    border-radius: 9px !important;
}

.txt-availability-name:disabled {
   background-color: #fff;
}

.week-days-container>label {
    margin-bottom: 0px;
    font-family: robotoBold;
    font-size: 15px;
}

.time-slot-container {
    min-height: calc(100vh - 42px);
}
.time-slot-container > div > .availability-info-text {
    font-family: robotoNormal;
    font-size: 13px;
}

.time-slot-button-container {
    display: flex;
}

.day-label-slot-width {
    min-width: 35px;
    text-align: center;
}

.time-slot-button-container>.btn-cancel {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    padding: 10px;
    width: 160px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.time-slot-button-container>.btn-save {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    width: 160px;
    color: #0a0afe;
    padding: 10px;
    border: 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.availability-email-container .email-content {
    margin-bottom: 8px !important;
}

.availability-email-container > .formik-error{
    font-size: 13px;
    font-family: robotoBold;
}

.availability-email-container{
    margin-bottom: 12px;
}

.email-content > div > .txt-availability-email {
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
    max-width: 447px;
    margin-right: 10px;
}