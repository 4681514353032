.public-page-container {
    height: 100vh;
    display: flex;
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
}

.public-page-container .react-reveal {
  padding: 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.public-page-panel{
    padding: 18px !important;
    height: 100%;
    overflow: auto;
    flex-direction: column;
}

.public-page-save-container > .btn-save {
  background: var(--button-link-color);
  border-radius: 6px;
  font-size: 15px;
  border: 0px;
  width: 160px;
  color: var(--text-color);
  padding: 10px;
  border: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.public-page-save-container {
  display: flex;
  justify-content: center;
}
