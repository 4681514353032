.widget-description-textarea {
  border: 0px !important;
  border-radius: 9px !important;
  min-height: 300px !important;
  box-shadow: 0px 1px 1px #ccc;
  color: #666a73;
}

.calendar-title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 0px;
}

.switch-field {
  overflow: hidden;
  padding: 0px;
}

.switch-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 14px;
  border-radius: 9px;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1); */
  transition: all 0.1s ease-in-out;
  width: 100%;
  box-shadow: 0px 1px 1px #ccc;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #fff;
  color: #000;
  box-shadow: none;
  width: 100%;
  border: 1px solid #0069ff;
  border-radius: 9px;
  box-shadow: 0px 1px 1px #ccc;
}

.radio-btn-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 10px;
}

.radio-btn-heading {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.radio-btn-subText {
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.radio-btn-img {
  width: 100%;
  max-width: 106px;
}

.share-btn-text {
  font-family: robotoNormal;
  font-size: 20px;
  margin-bottom: 6px;
}