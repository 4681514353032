
:root {
  --background-color: #F2F3FF;
  --text-color: #212529;
  --button-link-color: #e5e5ea;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: robotoNormal !important;
  font-weight: 400;
  overflow: hidden;
  min-height: 100vh;
  background-color: #efeff4 !important;
  color: #212529 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "robotoBold";
  src: local("robotoBold"), url("./Assets/Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "robotoNormal";
  src: local("robotoNormal"), url("./Assets/Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "robotoLighter";
  src: local("robotoLighter"), url("./Assets/Fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: lighter;
}

.direction-right {
  color: #8e8e93;
  font-size: 20px;
  display: flex;
}

.icon-container {
  height: 26px;
  width: 26px;
  background-color: #e5f1ff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container img {
  height: 18px;
  width: 18px;
  margin: 0;
}

.h-100 {
  height: 100% !important;
}

.back-button {
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  text-decoration: none;
}

.back-button img {
  margin-right: 5px;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.calendar-main-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: robotoBold;
  font-size: 25px;
  margin-bottom: 0px;
}

.calendar-main-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.calendar-header {
  font-family: robotoNormal;
  font-size: 17px;
  color: #007aff;
  cursor: pointer;
}

.main-calendar-link {
  direction: rtl;
  min-width: 0px;
}

.main-calendar-link::before {
  content: "s";
  font-size: 0px;
}
.main-calendar-link::after {
  content: "s";
  font-size: 0px;
}

.main-calendar-link-container {
  width: 100%;
  min-width: 0px;
}

.coming-soon-title {
  font-size: 14px;
  color: #707074;
}

.spinner-border-sm {
  border-width: 2px !important;
}

.add-email-button-container > .btn-save {
  background: #e5e5ea;
  border-radius: 6px;
  font-size: 15px;
  border: 0px;
  width: 160px;
  color: #0a0afe;
  padding: 10px;
  border: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.add-email-button-container {
  display: flex;
  justify-content: center;
}

.add-email-button-container > .btn-cancel {
  background: #e5e5ea;
  border-radius: 6px;
  font-size: 15px;
  border: 0px;
  padding: 10px;
  width: 160px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) {
  .back-button {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .calendar-main-title {
    font-size: 20px;
  }
}
