.get-user-info-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}


.get-user-info-component div div .lbl-email {
    color: var(--text-color);
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    opacity: 0.6;
}

.get-user-info-component div div .txt-email {
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
}

.calendar-event-time {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 0px;
}

.event-message {
    font-size: 14px;
}
.event-message > span {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

.event-title{
    color: green;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}


.email-error {
    font-size: 12px;
}

.slot-item {
    display: flex;
    flex-grow: 1;
    padding: 8px;
    padding-left: 0px;
    cursor: pointer;
}
.slot-item-container {
    background-color: #fff;
    padding-left: 16px;
    margin-bottom: 24px;
}

.slot-icon {
    height: 22px;
    width: 22px;
    background-color: #fff;
}

.slot-title {
    margin-left: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1.4;
}


